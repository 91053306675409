import { WidthContainer } from '@pata-app/core';

import React from 'react';

import SectionContainer from '../../../../components/sectionContainer/sectionContainer';
import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import TopFeatureTile from './topFeatureTile';
import { TOP_FEATURES_TITLES_CONFIG } from './topFeatures.config';
import { TilesContainer } from './topFeatures.styled';

const TopFeatureTitle = () => {
  return (
    <TextWithHighlight>
      <h2>
        Explore Pata's <span>top features </span>
        <br />
        to streamline your pet business
      </h2>
    </TextWithHighlight>
  );
};

export function TopFeatures() {
  return (
    <WidthContainer noBackground>
      <SectionContainer title={TopFeatureTitle}>
        <TilesContainer>
          {TOP_FEATURES_TITLES_CONFIG.map((tile) => (
            <TopFeatureTile title={tile.title} description={tile.description} icon={tile.icon} />
          ))}
        </TilesContainer>
      </SectionContainer>
    </WidthContainer>
  );
}
