import { WidthContainer } from '@pata-app/core';

import React from 'react';

import Icon from '../../../../components/icon/icon';
import SectionContainer from '../../../../components/sectionContainer/sectionContainer';
import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { FeatureTile } from './featureTile/featureTile';
import {
  FeatureHighlight,
  FeatureTileContent,
  FeatureTilesContainer,
  MessageCount,
  PercentageContainer,
} from './featureTile/featureTile.styled';
import { VatExclusion } from './paidFeatures.styled';

const TopFeatureTitle = () => {
  return (
    <TextWithHighlight>
      <h2>
        <span>Optional </span>
        paid features
      </h2>
    </TextWithHighlight>
  );
};

export function PaidFeatures() {
  return (
    <WidthContainer noBackground>
      <SectionContainer title={TopFeatureTitle}>
        <FeatureTilesContainer>
          <FeatureTile alignment="left" image={`${process.env.CDN_BASE_URL}/images/image-person-on-iphone.jpeg`}>
            <Icon size="80px" type="TextMessage" />
            <FeatureTileContent>
              <h3>SMS notifications</h3>
              <strong>Send up to 150 SMS notifications per month at no cost.</strong>
              <p>
                Need to send more? Upgrade to our SMS Notifications feature and pay just £0.05* per additional message.
              </p>

              <p>Perfect for keeping your clients informed and engaged.</p>
            </FeatureTileContent>
          </FeatureTile>
          <FeatureTile
            alignment="left"
            image={`${process.env.CDN_BASE_URL}/images/image-person-paying-with-bank-card.jpeg`}
          >
            <Icon size="80px" type="Membership" />
            <FeatureTileContent>
              <h3>Payment processing</h3>
              <strong>A fast & secure checkout experience for your customers.</strong>
              <p>Protection from no-shows & cancellations for yourself.</p>

              <p>Enjoy processing fees as low as 1.75%* + £0.45* per payment transaction.</p>
            </FeatureTileContent>
          </FeatureTile>
        </FeatureTilesContainer>
        <VatExclusion>
          <sup>
            <strong>*</strong>
          </sup>
          All fees are subject to VAT
        </VatExclusion>
      </SectionContainer>
    </WidthContainer>
  );
}
