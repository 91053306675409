import { Image, WidthContainer } from '@pata-app/core';

import React from 'react';

import SectionContainer from '../../../../components/sectionContainer/sectionContainer';
import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { SIGN_UP_PROCESS_CONFIG } from './signUpProcess.config';
import {
  ProcessesContainer,
  ProcessDot,
  ProcessItem,
  SignUpProcessContainer,
  SignUpProcessContent,
  SignUpProcessTitleContainer,
  ProcessContainer,
} from './signUpProcess.styled';

const SignUpProcessTitle = () => {
  return (
    <SignUpProcessTitleContainer>
      <h2>
        Online bookings for pet care
        <br />
        businesses made easy
      </h2>
    </SignUpProcessTitleContainer>
  );
};

export function SignUpProcess() {
  // const theme = useTheme();
  return (
    <SignUpProcessContainer>
      <Image
        src={`${process.env.CDN_BASE_URL}/images/image-phone-laptop-app.webp`}
        alt="Pata Partners app on laptop and iPhone"
        height={2000}
        width={2000}
      />
      <WidthContainer noBackground>
        <SectionContainer title={SignUpProcessTitle}>
          <SignUpProcessContent>
            <ProcessesContainer>
              {SIGN_UP_PROCESS_CONFIG.map((process) => (
                <ProcessItem key={process.title}>
                  {/* <ProcessDot /> */}
                  <ProcessContainer>
                    <strong>{process.number}.</strong>
                    <strong>{process.title}</strong>
                    <p>{process.description}</p>
                  </ProcessContainer>
                </ProcessItem>
              ))}
            </ProcessesContainer>
          </SignUpProcessContent>
        </SectionContainer>
      </WidthContainer>
    </SignUpProcessContainer>
  );
}
