import styled from 'styled-components';

export const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  row-gap: ${({ theme }) => theme.core.sizing[4]}px;
  column-gap: 50px;

  margin-top: 60px;

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    column-gap: 160px;
  } ;
`;
