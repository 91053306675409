import styled from 'styled-components';

export const BusinessCarouselContainer = styled.section``;

export const BusinessTilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  padding: 100px 0;
  margin: 0 ${({ theme }) => theme.core.sizing[2]}px;

  h2 {
    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;

    > span {
      color: ${({ theme }) => theme.core.colors.primary.green.medium};
    }
  }

  > div {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 300px;
    grid-auto-rows: max-content;
    gap: 20px;

    overflow-x: auto;
    scroll-snap-type: x mandatory;

    white-space: nowrap;

    a {
      height: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      scroll-snap-align: start;
    }

    @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
      margin: 0;
    }
  }
`;

export const TileContainer = styled.div`
  width: 100%;
`;
