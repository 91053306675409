import styled from 'styled-components';

export const CallToActionContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;

  overflow: hidden;

  background: ${({ theme }) => theme.core.colors.primary.teal.medium};
  > div {
    grid-column: 1 / span 5;
    grid-row: 1 / span 1;
  }

  img {
    grid-column: 1 / span 5;

    height: 300px;

    /* display: none; */
  }

  button {
    cursor: pointer;
    min-width: 150px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    img {
      display: flex;

      grid-column: 1 / span 5;
      grid-row: 1 / span 1;

      width: 100%;

      height: 600px;

      object-fit: cover;
      overflow: visible;

      object-position: left;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.xl}`} {
    img {
      display: flex;

      grid-column: 1 / span 5;
      grid-row: 1 / span 1;

      width: 100%;

      height: 600px;

      object-fit: cover;
    }
  }
`;

export const SignUpProcessTitleContainer = styled.div`
  p {
    margin: ${({ theme }) => theme.core.sizing[4]}px 0 ${({ theme }) => theme.core.sizing[2]}px 0;
  }
`;

export const SignUpProcessContent = styled.div`
  background: ${({ theme }) => theme.core.colors.greyscale.white};

  padding: 70px ${({ theme }) => theme.core.sizing[4]}px;

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    border-radius: ${({ theme }) => theme.core.border.element.radius}px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    padding: ${({ theme }) => theme.core.sizing[5]}px;

    width: 55%;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.xl}`} {
  }
`;

export const CallToActionTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 100%;
`;

export const ProcessesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[3]}px;

  margin: 0;

  padding: 0;

  svg {
    color: ${({ theme }) => theme.core.colors.primary.green.medium};
    width: 100px;
    height: 50px;
  }

  h2 {
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

export const ProcessContainer = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.core.sizing[3]}px;
  grid-template-columns: 30px auto;
  grid-template-rows: auto auto;
  align-items: flex-start;

  strong {
    &:first-of-type {
      align-self: flex-start;
      grid-row: 1 / span 2;

      font-size: 40px;
    }
    &:last-of-type {
      font-size: ${({ theme }) => theme.core.fontSizes.xl}px;
    }
  }

  p {
    color: ${({ theme }) => theme.core.colors.greyscale.black};
    width: 80%;
  }
`;

export const ProcessItem = styled.li`
  position: relative;

  display: flex;
  gap: ${({ theme }) => theme.core.sizing[2]}px;
  margin: 0;
  padding: 0;
  padding-bottom: ${({ theme }) => theme.core.sizing[3]}px;

  list-style: none;

  &:last-of-type {
    padding-bottom: 0;

    &:before {
      display: none;
    }
  }

  p {
    margin: 0;
  }
`;

export const ProcessDot = styled.div`
  flex-shrink: 0;

  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.core.colors.greyscale.white};
  border: 2px solid ${({ theme }) => theme.core.colors.primary.green.medium};
  border-radius: 50%;
  position: relative;
`;
