export const businessBenefits = [
  {
    title: 'Appointments bookings',
    description: 'Manage, add, rebook, cancel - the power is in your hands',
  },
  {
    title: 'Clients and email notifications',
    description: 'Make customer communications swiftly and easily',
  },
  {
    title: 'Locations / workplaces / branches',
    description: 'Add as many working places as you need',
  },
  {
    title: 'Number of team members ',
    description: 'Easily manage their shifts, services and time off slots',
  },
];
