import { Image, WidthContainer } from '@pata-app/core';
import { Icon } from '@pata-app/icons';
import { Button } from '@pata-app/ui-form';

import React from 'react';

import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { ActionContainer } from '../heroSection/heroSection.styled';
import { SIGN_UP_PROCESS_CONFIG } from './callToAction.config';
import {
  ProcessesContainer,
  ProcessItem,
  SignUpProcessContent,
  SignUpProcessTitleContainer,
  ProcessContainer,
  CallToActionContainer,
  CallToActionTextContainer,
} from './callToAction.styled';

export function CallToAction() {
  const handleSignUpClick = () => {
    window.location.href = `${process.env.PARTNERS_BASE_URL}/sign-up`;
  };

  const handleBookDemo = () => {
    window.location.href = `https://pata2.pipedrive.com/scheduler/vy45BdsA/pata-partners-demo`;
  };

  return (
    <CallToActionContainer>
      <Image
        src={`${process.env.CDN_BASE_URL}/images/image-lady-walking-dog.png`}
        alt="Lady walking a dog"
        height={3000}
        width={3000}
      />
      <WidthContainer noBackground>
        <CallToActionTextContainer>
          <SignUpProcessContent>
            <ProcessesContainer>
              <Icon type="PataLogo" />
              <h2>
                <TextWithHighlight>
                  <div>
                    Join the top UK pet care businesses{' '}
                    <span>
                      enjoying
                      <br />a free
                    </span>{' '}
                    and easy-to-use booking <br /> management platform.
                  </div>
                </TextWithHighlight>
              </h2>
              <p>
                From independent pet carers to large, established businesses, we support all types of pet services -
                including groomers, trainers, walkers, and more.
              </p>
              <ActionContainer>
                <Button onClick={handleSignUpClick}>Sign up today</Button>
                <Button buttonType="secondary" onClick={handleBookDemo}>
                  Book a demo
                </Button>
              </ActionContainer>
            </ProcessesContainer>
          </SignUpProcessContent>
        </CallToActionTextContainer>
      </WidthContainer>
    </CallToActionContainer>
  );
}
