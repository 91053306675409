import styled from 'styled-components';

export const VatExclusion = styled.p`
  /* max-width: 1000px; */

  width: 100%;

  margin: auto;
  margin-top: ${({ theme }) => theme.core.sizing[3]}px;

  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;
`;
